import React from 'react'

const Banner = () => {
  return (
    <div className="banner-wrapper-cr">
      <div className="container">
        <h2>Can’t find your perfect role?</h2>
        <p>
          Can't find a position that matches your profile? <br />
          Send us an email to <a className='acert' href="mailto:repairs@jetairmro.com">repairs@jetairmro.com</a> and
          let us know about your work experience.
        </p>
      </div>
    </div>
  )
}

export default Banner
