import React from 'react'

const jobTypeOptions = [
  { title: 'Full Time', value: 'fulltime' },
  { title: 'Part Time', value: 'part-time' },
]

type props = {
  locations: { label: string; value: string }[]
  setJobType: React.Dispatch<React.SetStateAction<string>>
  setName: React.Dispatch<React.SetStateAction<string>>
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  fetchJobs: VoidFunction
}
const Form: React.FC<props> = ({
  locations,
  setJobType,
  setName,
  isLoading,
  fetchJobs,
  setSelectedOption,
}) => {
  return (
    <div className="container form-section">
      <h2 className="form-title">Who are we looking for?</h2>
      <p className="form-sub-title">current openings</p>

      <div className="input-form-wrapper form-row">
        <div className="form-group col-md">
          <label htmlFor="emailInput1" className="label-search-form">
            Search
          </label>
          <input
            onChange={(e) => setName(e.target.value)}
            type="email"
            className="form-control input-single-search"
            id="emailInput1"
            placeholder="Ex. Account Manager"
          />
        </div>
        <div className="form-group col-md">
          <label htmlFor="exampleFormControlSelect1" className="label-search-form">
            Loaction
          </label>
          <select
            onChange={(e) => setSelectedOption(e.target.value)}
            className="form-control form-select input-single-search"
            id="exampleFormControlSelect1"
          >
            <option value="" selected className="placeholder-select-form-career">
              All
            </option>
            {locations &&
              locations.map((e, i) => (
                <option key={i} value={e.value}>
                  {e.label}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group col-md">
          <label htmlFor="exampleFormControlSelect1" className="label-search-form">
            Work Type
          </label>
          <select
            onChange={(e) => setJobType(e.target.value)}
            className="form-control input-single-search"
            id="exampleFormControlSelect1"
          >
            <option value="" selected className="placeholder-select-form-career">
              All
            </option>
            {jobTypeOptions.map((e, i) => (
              <option key={i} value={e.value}>
                {e.title}
              </option>
            ))}
          </select>
        </div>
        <button
          disabled={isLoading}
          onClick={() => {
            fetchJobs()
          }}
          className="btn-search-form"
        >
          <span className="text-btn">{isLoading ? '...Loading' : 'filter search'}</span>
          <img src="/images/search.svg" alt="img" />
        </button>
      </div>
    </div>
  )
}

export default Form
