import React, { useEffect, useRef, useState } from 'react'
import Layout from '@/components/Layout'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import SubHeader from '@/components/Common/SubHeader'
import WhyChooseUs from '@/components/Careers/WhyChooseUs'
import Form from '@/components/Careers/Form'
import ListJob from '@/components/Careers/ListJob'
import Banner from '@/components/Careers/Banner'
import Subscribe from '@/components/Careers/Subscribe'
import { API_URL, GATSBY_SIGNATURE } from '@/npc-api/config'
import axios from 'axios'
import { GTM } from '@/utils/google-tag-manager'
import { GTMEventType } from '@/types/google-tag-manager.interface'

const Career = () => {
  const [jobs, setJobs] = useState<any>([])
  const [options, setOptions] = useState<any[]>([])
  const [err, setError] = useState<any>(null)
  const [name, setName] = useState<string>('')
  const [location, setSelectedOption] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [jobType, setJobType] = useState('')

  const jobListRef = useRef<HTMLDivElement>(null)

  const fetchJobs = async () => {
    try {
      setIsLoading(true)

      GTM.sendEvent({
        event: GTMEventType.QueryJobs,
        job_location: location,
        job_name: name,
        job_type: jobType,
      })

      const res = await axios.post(`${API_URL}/jobs/allJobs`, {
        name,
        location,
        jobType,
        signature: GATSBY_SIGNATURE,
      })
      setJobs(res.data.slice(0, res.data.length - 1))
      const uniqueLocations = [
        ...new Set(res.data[res.data.length - 1].locations.map((e: any) => e)),
      ]
      const opt = uniqueLocations.map((key: any) => ({
        label: key,
        value: key,
      }))
      setIsLoading(false)
      setOptions(opt)
    } catch (error) {
      setIsLoading(false)
      setError(error)
    }
  }
  useEffect(() => {
    fetchJobs()
  }, [])
  return (
    <Layout>
      <SEO title="Career" canonical={useLocation().host} />
      <Header />
      <SubHeader
        title="Come work with us!"
        btnTitle={`View ${jobs.length} openings`}
        btnAction={() => {
          if (typeof window !== 'undefined' && jobListRef.current) {
            jobListRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            })
          }
        }}
      />
      <WhyChooseUs />
      <Form
        locations={options}
        setJobType={setJobType}
        setName={setName}
        isLoading={isLoading}
        fetchJobs={fetchJobs}
        setSelectedOption={setSelectedOption}
      />
      <div ref={jobListRef}>
        <ListJob jobsList={jobs} />
      </div>
      <Banner />
      <Subscribe />
      <Footer />
    </Layout>
  )
}

export default Career
