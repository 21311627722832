import React from 'react'

const dataItem = [
  {
    id: 0,
    title: 'Great salary',
    description: ` It's time to take your career to the next level! We will show you how a higher salary is within your reach and what steps you can take to get there.`,
  },
  {
    id: 1,
    title: 'Experienced leadership team',
    description: `A strong leadership team is essential for any company's success. Here's why an experienced leadership team is so important, and how you can make sure your company has one.`,
  },
  {
    id: 2,
    title: 'Flexibility to work anywhere',
    description: `Flexibility to work anywhere is one of the most important factors in choosing a job these days. We'll show you why it's so important and how you can make sure you get a job that offers this flexibility.`,
  },
  {
    id: 3,
    title: 'Why Joining Us Makes Sense',
    description: `Are you looking for a new challenge? A place where you can really make a difference? Then joining us is the right decision for you!`,
  },
  {
    id: 4,
    title: 'Job security',
    description: `Job security is something that everyone deserves but few are able to enjoy. We take a look at how job security is threatened and how it can be protected in the future.`,
  },
  {
    id: 5,
    title: 'Constant improvement',
    description:
      'The only way to keep growing and becoming better is through constant improvement. We invite you to join us in our journey of self-improvement.',
  },
]

const ItemChoose: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <div className="item-wp">
    <img className="img-choose-item" src="/images/check-circle.svg" alt="img" />
    <div className="">
      <span className="bag-t">{title}</span>
      <p className="text-description-choose">{description}</p>
    </div>
  </div>
)
const WhyChooseUs = () => {
  return (
    <div className="choose-section-wrapper">
      <div className="container ">
        <div className="titles-wrapper">
          <div className="titleJoin">Why join us?</div>
          <div className="subTitleJoin">You will love it here!</div>
        </div>

        <div className="items-wrapper-choose">
          {dataItem.map((e, inx) => (
            <ItemChoose title={e.title} description={e.description} key={inx} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUs
